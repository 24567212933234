<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2531_47816)">
      <path
        d="M15.832 0.5H4.16536C3.01536 0.5 2.08203 1.43333 2.08203 2.58333V18.4167C2.08203 19.5667 3.01536 20.5 4.16536 20.5H15.832C16.982 20.5 17.9154 19.5667 17.9154 18.4167V2.58333C17.9154 1.43333 16.982 0.5 15.832 0.5ZM9.9987 19.6667C9.30703 19.6667 8.7487 19.1083 8.7487 18.4167C8.7487 17.725 9.30703 17.1667 9.9987 17.1667C10.6904 17.1667 11.2487 17.725 11.2487 18.4167C11.2487 19.1083 10.6904 19.6667 9.9987 19.6667ZM16.2487 16.3333H3.7487V3H16.2487V16.3333Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2531_47816">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IcTablet',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
